<template>
  <div class="home fill-height pa-0">
    <main-nav></main-nav>

    <v-container fluid class="header bg-loop">
      <v-container>

      <v-row align="center" justify="center" class="fill-height" no-gutters>
        <!-- <v-col cols="12" class="pt-4 white--text text-center">
          <div class="iframe-wrapper" @click="refreshAnim()" >
            <div class="click-container"></div>
            <iframe ref="animatelogo" src="meetable.html" scrolling="no" style="width:240px; height:120px; margin:0; padding:0; border:none; overflow:hidden"></iframe>
          </div>
          <h1 class="text-h2 mb-1">Get on anyone's calendar.</h1>
          <p class="app-heading">Meet with experts in live video chats.</p>
          <br>
          <v-row align="center" justify="center">
            <v-col cols="11" md="5">
              <v-autocomplete
              label="Search by Expertise, Skills, or Names..."
              class="search"
              placeholder="Placeholder"
              prepend-inner-icon="mdi-magnify"
              solo
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col> -->
        <v-col cols="12" md="6" class="pt-4 white--text text-center text-md-left">
          <!-- <h1 class="text-h1 mb-1 text-xs-center text-md-left">Get time <br> on anyone's calendar.</h1> -->
          <div v-resize-text="{ratio:.65}" class="resize-text mb-4">Get time <br> on anyone's <br /> calendar.</div>
          <p class="text-h5 black--text subline mb-8">Meet with experts in live video chats.</p>

          <search></search>
          <!-- <v-row align="center" justify="space-between" no-gutters>
            <v-col cols="11" md="8" lg="8">
              <search></search>
            </v-col>
            <v-col cols="12" md="auto"><i>or</i></v-col>
            <v-col cols="12" md="auto">
              <v-btn color="black" dark>Browse</v-btn>
            </v-col>
          </v-row> -->
        </v-col>
        <v-col cols="12" md="6" align-content="end" class="phone">
          <img src="@/assets/phone.svg" alt="">
        </v-col>
      </v-row>
      </v-container>
    </v-container>
<!--
    <v-row align="center" justify="center">
      <v-col cols="12" class=" white--text text-xs-center">
        <v-btn-toggle
        v-model="toggle"
        class="toggle"
        mandatory
        >
        <v-btn>Get Advice</v-btn>
        <v-btn>Become Meetable</v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row> -->

    <v-container fluid>
    </v-container>

  </div>
</template>

<script>
// @ is an alias to /src
const mainNav = () => import("@/components/shared/nav.vue");
const search = () => import("@/components/shared/search.vue");

export default {
  name: 'Home',
  components: {
    mainNav, search
  },
  data: () => ({
    toggle: true,
  }),
  methods:{
    refreshAnim(){
      console.log('ok ok ');
      this.$refs.animatelogo.contentWindow.location.reload();
    }
  }
}
</script>

<style lang="scss">

.home{
  .header{
    padding: 7rem 0 0;
    > .container{
      padding-bottom: 0;
    }
    .subline{
      opacity: .45;
      color: #000;
    }
    h1{
      line-height: 5.5rem;
      font-size: 5.5rem!important;
    }
    .resize-text{
      line-height: 100%;
      font-weight: lighter;
      font-family: 'Nexa Extra Light', sans-serif!important;
    }
    .phone{
      background-image: url("~@/assets/phone.svg");
      background-size: cover;

      img{
        max-width:100%;
        visibility: hidden;
      }
    }
  }
  .search{
    // min-width:325px;
    // width:40vw;
    // margin: 0 auto;

  }
  .iframe-wrapper{
    position: relative;
    height:120px;
    width:240px;
    margin: 0 auto;
    // margin-bottom: -20px;
    cursor: pointer;
    .click-container{
      position: absolute;
      z-index: 999;
      top: 10px;
      height:100px;
      width:240px;
      // background-color: rgba(000, 000, 200, .5);
    }
  }
  .toggle {
    .v-btn{
      margin-top:-75px;
    }
  }
}

</style>
